















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
export default class Header extends Vue {
  register(): void {
    this.$router.push({ name: 'register' })
  }
  login(): void {
    this.$router.push({ name: 'login' })
  }
}
