import { VueConstructor } from 'vue'
import * as Antd from './exportAntd'
import { message, notification } from 'ant-design-vue'

export default function install(Vue: VueConstructor): VueConstructor {
  ;(Object.keys(Antd) as Array<keyof typeof Antd>).forEach(key => {
    Vue.use(Antd[key])
  })

  Vue.prototype.$message = message
  Vue.prototype.$notification = notification
  return Vue
}
