const api: Record<string, string> = {
  checkaccount: '/auth/check_account', // POST 检测是否注册
  send_code: '/auth/send_code', // POST 获取验证码
  check_code: '/auth/check_code', // POST 注册验证
  lms_login: '/auth/sms_login', // POST 短信登录
  login: '/auth/login', // POST 登录
  register: '/auth/register', // POST 注册
  reset_password: '/auth/retrieve_password', // POST 找回密码
  login_wb: '/auth/login_wb/callback', // POST 微博回调
  login_qq: '/auth/login_qq/callback', // POST qq回调
  login_wx: '/auth/login_wx/callback', // POST 微信回调git
  bind_verify: '/auth/bind_verify', // POST 检验手机号是否绑定过
  bind_phone: '/auth/login_third/bind', // POST 绑定手机号
  captcha_img: '/auth/captcha_login_img_get', // POST 图片验证列表
  is_exists: '/auth/is_exists' // POST 验证手机号是否注册
}
export default api