import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/auth/login'
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ 'views/auth/Login.vue')
  },
  {
    path: '/auth/login_sms',
    name: 'loginSms',
    component: () => import(/* webpackChunkName: "loginSms" */ 'views/auth/LoginSms.vue')
  },
  {
    path: '/auth/wechat_code',
    name: 'WeChatCode',
    component: () => import(/* webpackChunkName: "WeChatCode" */ 'views/auth/WeChatCode.vue')
  },
  {
    path: '/auth/bind_phone',
    name: 'bindPhone',
    component: () => import(/* webpackChunkName: "BindPhone" */ '../views/auth/BindPhone.vue')
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/auth/Register.vue')
  },
  {
    path: '/auth/set_password',
    name: 'setPassword',
    component: () => import(/* webpackChunkName: "SetPassword" */ '../views/auth/SetPassword.vue')
  },
  {
    path: '/auth/find_password',
    name: 'findPassword',
    component: () => import(/* webpackChunkName: "FindPassword" */ '../views/auth/FindPassword.vue')
  },
  {
    path: '/auth/other_login',
    name: 'otherLogin',
    component: () => import(/* webpackChunkName: "OtherLogin" */ '../views/auth/OtherLogin.vue')
  },
  {
    path: '/auth/wx_callback',
    name: 'wxCallback',
    component: () => import(/* webpackChunkName: "wxCallback" */ '../views/auth/WxCallBack.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ 'views/Test.vue')
  },
  {
    path: '/antd',
    name: 'antd',
    component: () => import(/* webpackChunkName: "test" */ 'views/antd/Index.vue'),
    children: [
      {
        path: '/button',
        name: 'button',
        component: () => import(/* webpackChunkName: "test" */ 'views/antd/Button.vue')
      }
    ]
  }
  // {
  //   path: '/auth/reset_password',
  //   name: 'resetPassword',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "resetPassword" */ 'views/auth/ResetPassword.vue'
  //     )
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
