import { isProd, getCookie, setCookie, removeCookie } from './common'

function getUrlOrigin(url: string): string {
  const regex = /https?:\/\/[^/]+/
  return (url.match(regex) as Array<string>)[0]
}
function formatUrl(url: string, token: string): string {
  return `${getUrlOrigin(url)}/auth/deal_ticket?ticket=${token}&to_url=${url}`
}

export function redirectOrigin(token: string): void {
  // let referrer = document.referrer
  let referrer = window.sessionStorage.getItem('referrer') || window.location.origin
  // 微信来源|直接打开sso跳转官网
  window.sessionStorage.removeItem('referrer')
  if (!referrer || getUrlOrigin(referrer) === window.location.origin) {
    if (isProd) {
      referrer = 'https://www.taihuoniao.com'
    } else {
      referrer = 'http://dev.taihuoniao.com'
    }
  }
  const url = formatUrl(referrer, token)
  console.log('url111', url)
  window.location.href = url
}
export function getToken(): string {
  if (isProd) {
    return getCookie('token')
  } else {
    return getCookie('dev_token')
  }
}

export function setToken(token: string): void {
  if (isProd) {
    setCookie('token', token, 30)
  } else {
    setCookie('dev_token', token, 30)
  }
  setCookie('set_user', '1', 30)
}
export function removeToken(): void {
  if (isProd) {
    removeCookie('token')
  } else {
    removeCookie('dev_token')
  }
}
