// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import http from '@/plugins/http'
import { VueConstructor } from 'vue'
class myServer {
  server = http
  nowHandle = {}

  v(vueObj: VueConstructor) {
    this.nowHandle = vueObj
  }
  parseRouter(
    moduleName: string,
    urlObj: stringObj
  ) {
    this[moduleName] = {}
    Object.keys(urlObj).forEach(apiName => {
      // 循环每个键, 注册方法
      this[moduleName][apiName] = this.sendMes.bind(
        this,
        moduleName,
        apiName,
        urlObj[apiName]
      )
    })
  }
  sendMes(
    moduleName: string,
    apiName: string,
    url: string,
    config: Partial<ajaxConfig>
  ) {
    const type = config.type || 'get'
    const data = config.data || ''
    const before = (res: any) => {
      this[moduleName][apiName].state = 'ready'
      return res
    }
    const defaultFn = (res: any) => {
      this.nowHandle[apiName] = res.data.data
    }
    const defaultFailFn = (error: any) => {
      console.error(error)
    }
    const success = config.success || defaultFn
    const fail = config.fail || defaultFailFn
    if (this[moduleName][apiName].state !== 'waiting') {
      this['server'][type](url, data).then(before).then(success).catch(fail)
      this[moduleName][apiName].state = 'waiting'
    }
  }
}
export default new myServer()
