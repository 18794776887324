














import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import header from '@/components/block/Header.vue'
import footer from '@/components/block/Footer.vue'
import { checkTHNDomain } from '@/assets/js/common'
import { redirectOrigin } from '@/assets/js/commonAuth'
@Component({
  components: {
    'v-header': header,
    'v-footer': footer
  }
})
export default class App extends Vue {
  isShowApp = false
  isFirst = true
  get token(): string {
    return this.$store.state.token
  }
  get isMob(): string {
    return this.$store.state.isMob
  }
  @Watch('$route')
  onRouteChanged(): void {
    if (this.isFirst) {
      this.init()
    }
  }
  init(): void {
    this.isFirst = false
    const referrer = (this.$route.query?.back_url as string) || document.referrer
    console.log('初始referrer', referrer)
    console.log('document.referrer', document.referrer)
    if (referrer) {
      const sourceDomain = checkTHNDomain(referrer)
      if (sourceDomain) {
        window.sessionStorage.setItem('referrer', referrer)
      }
    }
    this.checkToken()
  }
  checkToken(): void {
    if (!this.token) {
      this.isShowApp = true
      return
    }
    this.$qa.auth.checkTicket({
      type: 'post',
      data: {
        ticket: this.token
      },
      success: (res: any) => {
        if (res.data.meta.status_code === 200) {
          redirectOrigin(this.token)
        } else {
          this.$store.commit('logOut')
          this.isShowApp = true
          console.log('退出登录')
        }
      },
      fail: () => {
        this.$store.commit('logOut')
        this.isShowApp = true
        console.log('退出登录')
      }
    })
  }
}
