import axios, { AxiosRequestConfig, AxiosInstance } from 'axios'
import Qs from 'qs'
class Http {
  config: AxiosRequestConfig
  CancelToken = axios.CancelToken
  constructor(config: AxiosRequestConfig) {
    this.config = config
  }
  create() {
    const instance = axios.create(this.config)
    this.addInterceptors(instance)
    return instance
  }

  addInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(
      config => {
        return config
      },
      error => {
        console.error('http-err', error)
        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response) {
          const { status, data, headers, config, request } = error.response
          const response = {
            data: {
              data: data,
              meta: {
                status_code: status,
                message: data?.message
              }
            },
            status,
            headers,
            config,
            request
          }
          return Promise.reject(response)
        } else {
          return Promise.reject(error)
        }
      }
    )
  }
}

export default new Http({
  baseURL: location.origin + '/api',
  transformRequest: [
    function (data) {
      return Qs.stringify(data)
    }
  ],
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}).create()
