







import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
export default class Footer extends Vue {
  yearNow = new Date().getFullYear()
}
