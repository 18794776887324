import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from '@/assets/js/util'
import { message } from 'ant-design-vue'
import http from '@/plugins/http'
import api from '@/api/api'
const RVerify = require('@/assets/js/RVerify.js')
import qa from '@/plugins/request/index'

import 'normalize.css/normalize.css'
import 'ant-design-vue/dist/antd.less'
import 'styles/reset.less'
import 'styles/RVerify.css'
import 'styles/common.less'
import 'styles/base.less'
import 'styles/antd/change-antd.less'
import 'styles/antd/button.less'

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$http = http
Vue.prototype.$api = api
Vue.prototype.$RVerify = RVerify
Vue.prototype.$qa = qa

Vue.use(util)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
