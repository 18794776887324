import Vue from 'vue'
import Vuex from 'vuex'
import { isProd, isMob } from '@/assets/js/common'
import { redirectOrigin, setToken, getToken, removeToken } from '@/assets/js/commonAuth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
    isProd: isProd,
    isMob: isMob
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      setToken(token)
      redirectOrigin(token)
    },
    logOut(state) {
      state.token = ''
      removeToken()
    }
  }
})
