import isMobile from 'ismobilejs'
export const isMob = isMobile().any
export const is_weixin = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
export const showWxLogin = is_weixin || !isMob
export const isProd = process.env.NODE_ENV === 'production'
export const phoneRegex = /^1\d{10}$/

const ORIGIN = [
  {
    type: 1,
    domain: ['www.taihuoniao.com', 'm.taihuoniao.com', 'dev.taihuoniao.com', 't.taihuoniao.com']
  },
  {
    type: 2,
    domain: ['console.taihuoniao.com', 'mc.taihuoniao.com']
  },
  {
    type: 3,
    domain: ['saas.taihuoniao.com', 'saas-dev.taihuoniao.com']
  }
]
// 判断来源是否是太火鸟
export function checkTHNDomain(url: string): number {
  let type = 0
  ORIGIN.forEach(item => {
    item.domain.forEach(i => {
      if (url.includes(i)) {
        type = item.type
      }
    })
  })
  if (type) {
    return type
  } else {
    if (url.includes('weixin')) {
      return 0
    } else {
      return -1 // 本地环境, 微信之外的环境
    }
  }
}

export function generateUUID(): string {
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now() //use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

let hostArr: any = location.hostname.split('.')
const reg = new RegExp(/([0,1]?\d{1,2}|2([0-4][0-9]|5[0-5]))(\.([0,1]?\d{1,2}|2([0-4][0-9]|5[0-5]))){3}/g)
const matchIP = reg.test(location.host)
if (location.hostname === 'localhost' || matchIP) {
  hostArr = ''
} else {
  hostArr = '.' + (hostArr.length > 2 ? hostArr.slice(1).join('.') : hostArr.join('.'))
}

// cookie
export const getCookie = function (name: string): string {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : ''
}
export const setCookie = function (name: string, value: string, days: number): void {
  const d = 24 * 60 * 60 * days
  const data = name + '=' + value + ';path=/;domain=' + hostArr + ';max-age=' + d
  document.cookie = data
}
export const removeCookie = function (cookieString: string): void {
  const cookies = document.cookie.split(/;\s/g)
  cookies.forEach(function (item) {
    if (item.split('=')[0] == cookieString) {
      document.cookie = cookieString + '=;max-age=0;path=/'
      document.cookie = cookieString + '=;domain=' + hostArr + ';max-age=0;path=/'
    }
  })
}

function getAPPID(): string {
  if (isMob && is_weixin) {
    return 'wx75a9ffb78f202fb3'
  } else {
    if (isProd) {
      return 'wx34b60f01932004af'
    } else {
      return 'wx74c07debc5e2b784'
    }
  }
}

// 判断是否是移动端微信
export const wxUrl = function (): any {
  const url = `${location.origin}/auth/wx_callback`
  const appid = getAPPID()
  const state = generateUUID()
  let address
  if (isMob && is_weixin) {
    address = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&scope=snsapi_userinfo&response_type=code&evt=2&redirect_uri=${url}&state=${state}&type=wx#wechat_redirect`
  } else {
    address = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&scope=snsapi_login&evt=1&redirect_uri=${url}&state=${state}&type=wx&login_type=jssdk&self_redirect=default&href=data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30=`
  }

  return address
}
